@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'poppins', sans-serif;
  font-size: 16px;
}
html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  max-width: 1260px;
}

.footer--border {
  clip-path: polygon(0 0, 100% 0, 100% 25px, 0% 40px);
  transform: rotate(180deg);
}

.auth--container {
  height: calc(100vh - 120px);
}

.plans--tabs .MuiTabs-flexContainer {
  justify-content: flex-end;
}

.plans--tabs .MuiTabs-flexContainer .MuiTab-root.Mui-selected {
  color: #FFFFFF;
  background-color: #D3C033 !important;
}

.plans--tabs .MuiTabs-flexContainer .MuiTab-root {
  border-radius: 30px;
  color: #172755;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  transition: 0.2s ease-in-out;
}

.MuiTabs-indicator {
  display: none !important;
}

.banner--div {
  background: url('../public/background-top.png') no-repeat;
  background-size: cover;
  background-color: #FFFFFF;
  background-position: bottom center;
}

.footer--div {
  background: url('../public/background-bottom.png') no-repeat;
  background-size: cover;
  background-color: #FFFFFF;
  background-position: top center;
}

.account--tab-btns {
  border-radius: 9999px !important;
  padding: 0px !important;
  overflow: hidden;
  font-weight: 600;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.account--tab-btn {
  border: 0 !important;
  padding: 15px !important;
  min-width: 150px !important;
  font-size: 14px;
  font-family: 'poppins', sans-serif;
  border-radius: 9999px !important;
  color: #000000 !important;
  text-transform: capitalize !important;
}

.account--tab-btn.active {
  background: #D3C033 !important;
  color: #FFFFFF !important;
}

.tx--shadow {
  box-shadow: 0px 4px 8px 3px #00000040;
}

.tbl-pagination--center>.MuiPagination-ul {
  justify-content: center;
}

ul.MuiPagination-ul * {
  font-family: 'poppins', sans-serif !important;
}

ul.MuiPagination-ul li button.Mui-selected {
  background: #155896;
  color: #FFFFFF;
}

ul.MuiPagination-ul li button {
  background: #E0E0E0;
}

ul.MuiPagination-ul li:nth-child(1) button,
ul.MuiPagination-ul li:nth-last-child(1) button {
  background: transparent;
}

ul.MuiPagination-ul li button {
  border-radius: 10px;
}

.tx-tbl .rdt_TableHeadRow .rdt_TableCol>div>div {
  font-weight: bold;
}

.information--tab-btns {
  border-radius: 0 !important;
  width: 100% !important;
  border-bottom: 2px solid #B6B6B6;
}

.information--tab-btn {
  border: 0 !important;
  font-weight: 400 !important;
  color: #000000 !important;
  text-transform: capitalize !important;
  font-size: 1.1rem !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.information--tab-btn.active {
  font-weight: bold !important;
  border-bottom: 2px solid #D3C033 !important;
}

.tx--line-chart {
  width: 100%;
}


.tx--line-chart .MuiChartsLegend-series,
.tx--pie-chart .MuiChartsLegend-column {
  display: none;
}

.upload--box .MuiPopover-paper {
  border-radius: 8px !important;
  box-shadow: 0px 4px 8px 3px #00000040 !important;
}

.upload-box--label .MuiFormControlLabel-label {
  font-weight: bold;
  color: #515151;
}

.upload-box--inputs {
  box-shadow: 0px 4px 8px 3px #00000040;
  border-radius: 8px;
  overflow: hidden;
}

.upload-box--inputs .upload-box--select {
  background: #F3F3F3;
  border-radius: 0 !important;
}

.upload-box--inputs label {
  padding: 10px;
  font-weight: 600;
}

.checkout-form-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f9fafb;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  text-align: center;
}

h2 {
  margin-bottom: 1rem;
  color: #2d3748;
}

.checkout-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

label {
  font-size: 14px;
  color: #4a5568;
  text-align: left;
}

#card-element {
  padding: 0.5rem;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  background-color: #fff;
}

.card-error {
  color: #e53e3e;
  font-size: 14px;
  margin-top: 0.5rem;
}

.pay-button {
  padding: 0.75rem 1.5rem;
  background-color: #3182ce;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.pay-button:hover {
  background-color: #2b6cb0;
}

.pay-button:disabled {
  background-color: #cbd5e0;
  cursor: not-allowed;
}

.success-message {
  color: #38a169;
  font-size: 18px;
  font-weight: bold;
}

.pie-chart-legend {
  position: absolute;
  bottom: 0;
  right: 0;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    outline: none;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 1000;
    color: #fff;
}

/* Increase font size for mobile devices */
@media screen and (max-width: 768px) {
  body {
    font-size: 18px;
  }
  
  h1 {
    font-size: 2.2em;
  }
  
  h2 {
    font-size: 1.8em;
  }
  
  p {
    font-size: 1.1em;
    line-height: 1.6;
  }
}

/* Even larger for very small screens */
@media screen and (max-width: 480px) {
  body {
    font-size: 20px;
  }
}

/* Tablet-specific styles */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* General typography adjustments */
  body {
    font-size: 17px;
  }

  /* Container adjustments for tablets */
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  /* Auth container height adjustment */
  .auth--container {
    height: calc(100vh - 100px);
    padding: 20px;
  }

  /* Account tab button adjustments */
  .account--tab-btn {
    min-width: 120px !important;
    padding: 12px !important;
  }

  /* Modal content adjustments */
  .modal-content {
    width: 90%;
    padding: 16px;
  }

  /* Chart responsiveness */
  .tx--line-chart,
  .tx--pie-chart {
    width: 100%;
    height: auto;
    min-height: 300px;
  }

  /* Information tab adjustments */
  .information--tab-btn {
    font-size: 1rem !important;
    padding: 10px !important;
  }

  /* Upload box adjustments */
  .upload-box--inputs label {
    padding: 8px;
  }

  /* Menu item adjustments */
  .menuItemClass {
    width: 100%;
    text-align: center;
    padding: 10px 20px;
    margin: 4px 0;
  }

  /* Drawer specific styles */
  .MuiDrawer-paper {
    background-color: #155896 !important;
    width: 100% !important;
    max-width: 300px !important;
  }

  .mobile-menu-items {
    width: 100%;
    padding: 16px;
  }

  .mobile-menu-items > * {
    width: 100%;
  }

  /* Language dropdown adjustments */
  .language-dropdown {
    right: 16px;
  }

  /* Menu button styling */
  .menu-button {
    padding: 8px;
    margin-right: -8px;
  }

  /* Logo size adjustment */
  .logo-container {
    width: 120px;
  }

  /* Ensure proper spacing in mobile menu */
  .mobile-menu-items > div {
    margin-bottom: 12px;
  }

  /* Dropdown menu positioning */
  .dropdown-menu {
    width: 100%;
    position: static;
    margin-top: 8px;
  }

  /* Button and link styles in mobile menu */
  .mobile-menu-items a,
  .mobile-menu-items button {
    width: 100%;
    justify-content: center;
    text-align: center;
    padding: 12px 20px;
    margin: 4px 0;
  }
}

/* Landscape tablet orientation */
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .auth--container {
    height: calc(100vh - 80px);
  }

  .container {
    max-width: 900px;
    margin: 0 auto;
  }
}

/* For iPads Pro and larger tablets */
@media screen and (min-width: 1024px) and (max-width: 1260px) {
  .container {
    max-width: 960px;
    margin: 0 auto;
    padding-left: 32px;
    padding-right: 32px;
  }
}

/* Additional styles for drawer backdrop */
.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

/* Ensure drawer transitions are smooth */
.MuiDrawer-root .MuiDrawer-paper {
    transition: transform 0.3s ease-in-out !important;
}

/* Additional styles for drawer */
.MuiDrawer-root .MuiDrawer-paper {
  background-color: #155896 !important;
}

/* Ensure proper text color in drawer */
.MuiDrawer-root .MuiDrawer-paper * {
  color: white;
}

/* Ensure proper button styling in drawer */
.MuiDrawer-root .MuiDrawer-paper button,
.MuiDrawer-root .MuiDrawer-paper a {
  width: 100%;
  text-align: center;
  margin: 4px 0;
  padding: 12px 20px;
  border-radius: 9999px;
}

/* Hover effects for drawer items */
.MuiDrawer-root .MuiDrawer-paper button:hover,
.MuiDrawer-root .MuiDrawer-paper a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}