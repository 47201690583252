.payment-fail-container {
    text-align: center;
    padding: 40px;
    background-color: #f8f8f8;
    border-radius: 12px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
    max-width: 600px;
    margin: 0 auto;
}

.fail-illustration {
    position: relative;
    height: 200px;
    margin-bottom: 30px;
}

/* Red X mark circle */
.fail-circle {
    position: absolute;
    left: 20%;
    width: 100px;
    height: 100px;
    background-color: #FF3B30;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.x-mark {
    position: relative;
    width: 50px;
    height: 50px;
}

.x-mark:before,
.x-mark:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: white;
    border-radius: 4px;
}

.x-mark:before {
    transform: rotate(45deg);
}

.x-mark:after {
    transform: rotate(-45deg);
}

/* Receipt with FAILED stamp */
.receipt {
    position: absolute;
    right: 20%;
    top: 40px;
    width: 140px;
    height: 160px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    overflow: hidden;
}

.receipt-lines {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.line {
    height: 4px;
    background-color: #f0f0f0;
    margin: 8px 0;
    border-radius: 2px;
}

.failed-stamp {
    position: absolute;
    color: #FF3B30;
    font-size: 24px;
    font-weight: bold;
    border: 4px solid #FF3B30;
    padding: 5px 10px;
    transform: rotate(-30deg);
    letter-spacing: 1px;
}

/* Text styles */
.payment-fail-container h1 {
    color: #333;
    font-size: 24px;
    margin-bottom: 12px;
}

.payment-fail-container p {
    color: #666;
    margin-bottom: 24px;
}

/* Button styles */
.button-fail {
    background-color: #FF3B30;
    color: white;
    border: none;
    padding: 12px 28px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
}

.button-fail:hover {
    background-color: #e02e24;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(255, 59, 48, 0.3);
}

/* For the original animation if you want to keep it as a fallback */
.sad-face-animation {
    display: none; /* Hide by default, you can remove this line if you want to show it */
    font-size: 3rem;
    animation: shake 0.5s ease-in-out infinite;
}

@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
} 