.payment-success-container {
    text-align: center;
    padding: 40px;
    background-color: #f8f8f8;
    border-radius: 12px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
    max-width: 600px;
    margin: 0 auto;
}

.success-illustration {
    position: relative;
    height: 200px;
    margin-bottom: 30px;
}

/* Green checkmark circle */
.check-circle {
    position: absolute;
    left: 20%;
    width: 100px;
    height: 100px;
    background-color: #4CD964;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.checkmark {
    width: 40px;
    height: 70px;
    border-right: 8px solid white;
    border-bottom: 8px solid white;
    transform: rotate(45deg) translate(-5px, -10px);
}

/* Receipt with PAID stamp */
.receipt {
    position: absolute;
    right: 20%;
    top: 40px;
    width: 140px;
    height: 160px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    overflow: hidden;
}

.receipt-lines {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.line {
    height: 4px;
    background-color: #f0f0f0;
    margin: 8px 0;
    border-radius: 2px;
}

.paid-stamp {
    position: absolute;
    color: #4CD964;
    font-size: 28px;
    font-weight: bold;
    border: 4px solid #4CD964;
    padding: 5px 10px;
    transform: rotate(-30deg);
    letter-spacing: 2px;
}

/* Text styles */
.payment-success-container h1 {
    color: #333;
    font-size: 24px;
    margin-bottom: 12px;
}

.payment-success-container p {
    color: #666;
    margin-bottom: 24px;
}

/* Button styles */
.button-success {
    background-color: #4CD964;
    color: white;
    border: none;
    padding: 12px 28px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
}

.button-success:hover {
    background-color: #41b655;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(76, 217, 100, 0.3);
}